'use client';
import { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Footer from '@/app/components/navigation/Footer';

type Props = {
  children: ReactNode;
};

export default function AccountLayout({ children }: Props) {
  return (
    <Container fluid>
      <Row className={'mt-layout justify-content-center'}>
        <Col className="content-column" style={{ maxWidth: '26rem' }}>
          <div className="pt-3">
            <div className="pt-5">{children}</div>
          </div>
        </Col>
        <Footer dark />
      </Row>
    </Container>
  );
}
